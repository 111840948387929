import useMenuNavigation from '@/hooks/useNavigation';
import { project } from '@/lib/project';
import Image from 'next/image';
import Link from 'next/link';
import { StaticLogo } from './StaticLogos';

export function Logo(props) {
	const { linkClassName, className, width, height, priority } = props;
	const navigation = useMenuNavigation();
	return (
		<Link
			href={navigation.home.href || '/'}
			className={linkClassName || ''}
			title={`${project.name} logo`}
		>
			<span className="sr-only">{project.name}</span>
			<Image
				alt={`${project.name} logo`}
				title={`${project.name} logo`}
				priority={priority}
				src={StaticLogo({ id: project.id })}
				width={width}
				height={height}
				className={className}
			/>
		</Link>
	);
}

const path = require('path');
const { project } = require('./src/lib/project');

const locales = project.locales;
if (project.includeDefaultLocaleInPath) {
	locales.unshift('default');
}

module.exports = {
	i18n: {
		locales: locales,
		defaultLocale: project.includeDefaultLocaleInPath
			? 'default'
			: project.defaultLocale,
		localeDetection: false,
	},
	localePath:
		typeof window === 'undefined'
			? path.resolve(`./public/locales`)
			: '/locales',
	reloadOnPrerender: process.env.NODE_ENV !== 'production',
};

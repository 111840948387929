import { pageView } from '@/lib/google-analytics';
import { Router } from 'next/router';
import { useEffect } from 'react';

export function useGAPageViews({ gaMeasurementId, ignoreHashChange }) {
	useEffect(() => {
		const handleRouteChange = (url) => {
			if (gaMeasurementId) {
				pageView({ path: url.toString() }, gaMeasurementId);
			}
		};

		Router.events.on('routeChangeComplete', handleRouteChange);

		if (!ignoreHashChange) {
			Router.events.on('hashChangeComplete', handleRouteChange);
		}

		return () => {
			Router.events.off('routeChangeComplete', handleRouteChange);

			if (!ignoreHashChange) {
				Router.events.off('hashChangeComplete', handleRouteChange);
			}
		};
	}, [gaMeasurementId, ignoreHashChange]);
}

module.exports.trainCompaniesRedirects = [
	{
		source: '/en/union-pearson-express-bus-tickets',
		destination: '/en/companies/union-pearson-express',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/union-pearson-express-boletos-de-autobus',
		destination: '/es-MX/companies/union-pearson-express',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-blue-water-bus-tickets',
		destination: '/en/companies/amtrak-blue-water',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-blue-water-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-blue-water',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-city-of-new-orleans-bus-tickets',
		destination: '/en/companies/amtrak-city-of-new-orleans',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-city-of-new-orleans-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-city-of-new-orleans',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/tgv-ouigo-bus-tickets',
		destination: '/en/companies/tgv-ouigo',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/tgv-ouigo-boletos-de-autobus',
		destination: '/es-MX/companies/tgv-ouigo',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/transilien-bus-tickets',
		destination: '/en/companies/transilien',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/transilien-boletos-de-autobus',
		destination: '/es-MX/companies/transilien',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/sncf-rer-bus-tickets',
		destination: '/en/companies/sncf-rer',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/sncf-rer-boletos-de-autobus',
		destination: '/es-MX/companies/sncf-rer',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/mav-bus-tickets',
		destination: '/en/companies/mav',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/mav-boletos-de-autobus',
		destination: '/es-MX/companies/mav',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-carolinian-bus-tickets',
		destination: '/en/companies/amtrak-carolinian',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-carolinian-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-carolinian',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/sncf-lex-bus-tickets',
		destination: '/en/companies/sncf-lex',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/sncf-lex-boletos-de-autobus',
		destination: '/es-MX/companies/sncf-lex',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/eurostar-bus-tickets',
		destination: '/en/companies/eurostar',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/eurostar-boletos-de-autobus',
		destination: '/es-MX/companies/eurostar',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-pere-marquette-bus-tickets',
		destination: '/en/companies/amtrak-pere-marquette',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-pere-marquette-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-pere-marquette',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/arriva-train-bus-tickets',
		destination: '/en/companies/arriva-train',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/arriva-train-boletos-de-autobus',
		destination: '/es-MX/companies/arriva-train',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/sncf-intercites-bus-tickets',
		destination: '/en/companies/sncf-intercites',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/sncf-intercites-boletos-de-autobus',
		destination: '/es-MX/companies/sncf-intercites',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/grand-central-bus-tickets',
		destination: '/en/companies/grand-central',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/grand-central-boletos-de-autobus',
		destination: '/es-MX/companies/grand-central',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-pennsylvanian-bus-tickets',
		destination: '/en/companies/amtrak-pennsylvanian',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-pennsylvanian-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-pennsylvanian',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-california-zephyr-bus-tickets',
		destination: '/en/companies/amtrak-california-zephyr',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-california-zephyr-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-california-zephyr',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-illini-service-bus-tickets',
		destination: '/en/companies/amtrak-illini-service',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-illini-service-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-illini-service',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/sbb-bus-tickets',
		destination: '/en/companies/sbb',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/sbb-boletos-de-autobus',
		destination: '/es-MX/companies/sbb',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/erixx-bus-tickets',
		destination: '/en/companies/erixx',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/erixx-boletos-de-autobus',
		destination: '/es-MX/companies/erixx',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/chiltren-railway-bus-tickets',
		destination: '/en/companies/chiltren-railway',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/chiltren-railway-boletos-de-autobus',
		destination: '/es-MX/companies/chiltren-railway',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/autrains-bus-tickets',
		destination: '/en/companies/autrains',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/autrains-boletos-de-autobus',
		destination: '/es-MX/companies/autrains',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/arlanda-express-bus-tickets',
		destination: '/en/companies/arlanda-express',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/arlanda-express-boletos-de-autobus',
		destination: '/es-MX/companies/arlanda-express',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/great-northern-bus-tickets',
		destination: '/en/companies/great-northern',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/great-northern-boletos-de-autobus',
		destination: '/es-MX/companies/great-northern',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/great-western-railway-bus-tickets',
		destination: '/en/companies/great-western-railway',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/great-western-railway-boletos-de-autobus',
		destination: '/es-MX/companies/great-western-railway',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/iryo-bus-tickets',
		destination: '/en/companies/iryo',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/iryo-boletos-de-autobus',
		destination: '/es-MX/companies/iryo',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/deutsche-bahn-intercity-express-ice-bus-tickets',
		destination: '/en/companies/deutsche-bahn-intercity-express-ice',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/deutsche-bahn-intercity-express-ice-boletos-de-autobus',
		destination: '/es-MX/companies/deutsche-bahn-intercity-express-ice',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/trenitalia-bus-tickets',
		destination: '/en/companies/trenitalia',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/trenitalia-boletos-de-autobus',
		destination: '/es-MX/companies/trenitalia',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-capitol-corridor-bus-tickets',
		destination: '/en/companies/amtrak-capitol-corridor',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-capitol-corridor-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-capitol-corridor',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/sj-trains-bus-tickets',
		destination: '/en/companies/sj-trains',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/sj-trains-boletos-de-autobus',
		destination: '/es-MX/companies/sj-trains',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/northern-railway-bus-tickets',
		destination: '/en/companies/northern-railway',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/northern-railway-boletos-de-autobus',
		destination: '/es-MX/companies/northern-railway',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-capitol-limited-bus-tickets',
		destination: '/en/companies/amtrak-capitol-limited',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-capitol-limited-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-capitol-limited',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/lumo-bus-tickets',
		destination: '/en/companies/lumo',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/lumo-boletos-de-autobus',
		destination: '/es-MX/companies/lumo',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-lake-shore-limited-bus-tickets',
		destination: '/en/companies/amtrak-lake-shore-limited',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-lake-shore-limited-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-lake-shore-limited',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/metrolinx-bus-tickets',
		destination: '/en/companies/metrolinx',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/metrolinx-boletos-de-autobus',
		destination: '/es-MX/companies/metrolinx',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-texas-eagle-bus-tickets',
		destination: '/en/companies/amtrak-texas-eagle',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-texas-eagle-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-texas-eagle',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/sncf-nomad-bus-tickets',
		destination: '/en/companies/sncf-nomad',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/sncf-nomad-boletos-de-autobus',
		destination: '/es-MX/companies/sncf-nomad',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/deutsche-bahn-regional-express-bus-tickets',
		destination: '/en/companies/deutsche-bahn-regional-express',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/deutsche-bahn-regional-express-boletos-de-autobus',
		destination: '/es-MX/companies/deutsche-bahn-regional-express',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/caledonian-sleeper-bus-tickets',
		destination: '/en/companies/caledonian-sleeper',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/caledonian-sleeper-boletos-de-autobus',
		destination: '/es-MX/companies/caledonian-sleeper',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-illinois-zephyr-bus-tickets',
		destination: '/en/companies/amtrak-illinois-zephyr',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-illinois-zephyr-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-illinois-zephyr',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-palmetto-bus-tickets',
		destination: '/en/companies/amtrak-palmetto',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-palmetto-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-palmetto',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/oebb-bus-tickets',
		destination: '/en/companies/oebb',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/oebb-boletos-de-autobus',
		destination: '/es-MX/companies/oebb',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-ethan-allen-express-bus-tickets',
		destination: '/en/companies/amtrak-ethan-allen-express',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-ethan-allen-express-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-ethan-allen-express',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/hull-trains-bus-tickets',
		destination: '/en/companies/hull-trains',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/hull-trains-boletos-de-autobus',
		destination: '/es-MX/companies/hull-trains',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-saluki-bus-tickets',
		destination: '/en/companies/amtrak-saluki',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-saluki-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-saluki',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/south-western-railway-bus-tickets',
		destination: '/en/companies/south-western-railway',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/south-western-railway-boletos-de-autobus',
		destination: '/es-MX/companies/south-western-railway',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-heartland-flyer-bus-tickets',
		destination: '/en/companies/amtrak-heartland-flyer',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-heartland-flyer-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-heartland-flyer',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-downeaster-bus-tickets',
		destination: '/en/companies/amtrak-downeaster',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-downeaster-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-downeaster',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/elizabeth-line-bus-tickets',
		destination: '/en/companies/elizabeth-line',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/elizabeth-line-boletos-de-autobus',
		destination: '/es-MX/companies/elizabeth-line',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-auto-train-bus-tickets',
		destination: '/en/companies/amtrak-auto-train',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-auto-train-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-auto-train',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/brightline-bus-tickets',
		destination: '/en/companies/brightline',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/brightline-boletos-de-autobus',
		destination: '/es-MX/companies/brightline',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/deutsche-bahn-bus-tickets',
		destination: '/en/companies/deutsche-bahn',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/deutsche-bahn-boletos-de-autobus',
		destination: '/es-MX/companies/deutsche-bahn',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-lincoln-service-bus-tickets',
		destination: '/en/companies/amtrak-lincoln-service',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-lincoln-service-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-lincoln-service',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/serbian-railways-bus-tickets',
		destination: '/en/companies/serbian-railways',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/serbian-railways-boletos-de-autobus',
		destination: '/es-MX/companies/serbian-railways',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/greater-anglia-bus-tickets',
		destination: '/en/companies/greater-anglia',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/greater-anglia-boletos-de-autobus',
		destination: '/es-MX/companies/greater-anglia',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/tmb-bus-tickets',
		destination: '/en/companies/tmb',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/tmb-boletos-de-autobus',
		destination: '/es-MX/companies/tmb',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/eurocity-bus-tickets',
		destination: '/en/companies/eurocity',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/eurocity-boletos-de-autobus',
		destination: '/es-MX/companies/eurocity',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/trenord-bus-tickets',
		destination: '/en/companies/trenord',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/trenord-boletos-de-autobus',
		destination: '/es-MX/companies/trenord',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/cross-country-bus-tickets',
		destination: '/en/companies/cross-country',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/cross-country-boletos-de-autobus',
		destination: '/es-MX/companies/cross-country',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/london-midland-railway-bus-tickets',
		destination: '/en/companies/london-midland-railway',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/london-midland-railway-boletos-de-autobus',
		destination: '/es-MX/companies/london-midland-railway',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/leo-express-bus-tickets',
		destination: '/en/companies/leo-express',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/leo-express-boletos-de-autobus',
		destination: '/es-MX/companies/leo-express',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/campania-express-bus-tickets',
		destination: '/en/companies/campania-express',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/campania-express-boletos-de-autobus',
		destination: '/es-MX/companies/campania-express',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/pkp-intercity-bus-tickets',
		destination: '/en/companies/pkp-intercity',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/pkp-intercity-boletos-de-autobus',
		destination: '/es-MX/companies/pkp-intercity',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/west-midlands-railway-bus-tickets',
		destination: '/en/companies/west-midlands-railway',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/west-midlands-railway-boletos-de-autobus',
		destination: '/es-MX/companies/west-midlands-railway',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-pacific-surfliner-bus-tickets',
		destination: '/en/companies/amtrak-pacific-surfliner',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-pacific-surfliner-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-pacific-surfliner',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-bus-tickets',
		destination: '/en/companies/amtrak',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/thalys-bus-tickets',
		destination: '/en/companies/thalys',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/thalys-boletos-de-autobus',
		destination: '/es-MX/companies/thalys',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/sweg-bus-tickets',
		destination: '/en/companies/sweg',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/sweg-boletos-de-autobus',
		destination: '/es-MX/companies/sweg',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/renfe-bus-tickets',
		destination: '/en/companies/renfe',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/renfe-boletos-de-autobus',
		destination: '/es-MX/companies/renfe',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/westfalen-bahn-bus-tickets',
		destination: '/en/companies/westfalen-bahn',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/westfalen-bahn-boletos-de-autobus',
		destination: '/es-MX/companies/westfalen-bahn',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-cardinal-bus-tickets',
		destination: '/en/companies/amtrak-cardinal',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-cardinal-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-cardinal',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/mersey-rail-bus-tickets',
		destination: '/en/companies/mersey-rail',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/mersey-rail-boletos-de-autobus',
		destination: '/es-MX/companies/mersey-rail',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/up-express-metrolinx-bus-tickets',
		destination: '/en/companies/up-express-metrolinx',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/up-express-metrolinx-boletos-de-autobus',
		destination: '/es-MX/companies/up-express-metrolinx',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/southern-bus-tickets',
		destination: '/en/companies/southern',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/southern-boletos-de-autobus',
		destination: '/es-MX/companies/southern',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-crescent-bus-tickets',
		destination: '/en/companies/amtrak-crescent',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-crescent-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-crescent',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/intercity-bus-tickets',
		destination: '/en/companies/intercity',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/intercity-boletos-de-autobus',
		destination: '/es-MX/companies/intercity',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/irish-rail-bus-tickets',
		destination: '/en/companies/irish-rail',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/irish-rail-boletos-de-autobus',
		destination: '/es-MX/companies/irish-rail',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-northeast-regional-bus-tickets',
		destination: '/en/companies/amtrak-northeast-regional',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-northeast-regional-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-northeast-regional',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-maple-leaf-bus-tickets',
		destination: '/en/companies/amtrak-maple-leaf',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-maple-leaf-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-maple-leaf',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-keystone-service-bus-tickets',
		destination: '/en/companies/amtrak-keystone-service',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-keystone-service-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-keystone-service',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-sunset-limited-bus-tickets',
		destination: '/en/companies/amtrak-sunset-limited',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-sunset-limited-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-sunset-limited',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-san-joaquins-bus-tickets',
		destination: '/en/companies/amtrak-san-joaquins',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-san-joaquins-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-san-joaquins',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/cfr-calatori-bus-tickets',
		destination: '/en/companies/cfr-calatori',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/cfr-calatori-boletos-de-autobus',
		destination: '/es-MX/companies/cfr-calatori',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/avanti-west-coast-bus-tickets',
		destination: '/en/companies/avanti-west-coast',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/avanti-west-coast-boletos-de-autobus',
		destination: '/es-MX/companies/avanti-west-coast',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/klia-ekspres-bus-tickets',
		destination: '/en/companies/klia-ekspres',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/klia-ekspres-boletos-de-autobus',
		destination: '/es-MX/companies/klia-ekspres',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/sncf-ter-bus-tickets',
		destination: '/en/companies/sncf-ter',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/sncf-ter-boletos-de-autobus',
		destination: '/es-MX/companies/sncf-ter',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-missouri-river-runner-bus-tickets',
		destination: '/en/companies/amtrak-missouri-river-runner',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-missouri-river-runner-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-missouri-river-runner',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-piedmont-bus-tickets',
		destination: '/en/companies/amtrak-piedmont',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-piedmont-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-piedmont',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-empire-service-bus-tickets',
		destination: '/en/companies/amtrak-empire-service',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-empire-service-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-empire-service',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/midland-railway-butterley-bus-tickets',
		destination: '/en/companies/midland-railway-butterley',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/midland-railway-butterley-boletos-de-autobus',
		destination: '/es-MX/companies/midland-railway-butterley',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/sncf-tgv-bus-tickets',
		destination: '/en/companies/sncf-tgv',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/sncf-tgv-boletos-de-autobus',
		destination: '/es-MX/companies/sncf-tgv',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/bulgarian-state-railways-bus-tickets',
		destination: '/en/companies/bulgarian-state-railways',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/bulgarian-state-railways-boletos-de-autobus',
		destination: '/es-MX/companies/bulgarian-state-railways',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/westbahn-bus-tickets',
		destination: '/en/companies/westbahn',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/westbahn-boletos-de-autobus',
		destination: '/es-MX/companies/westbahn',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-hiawatha-bus-tickets',
		destination: '/en/companies/amtrak-hiawatha',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-hiawatha-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-hiawatha',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/nighjet-bus-tickets',
		destination: '/en/companies/nighjet',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/nighjet-boletos-de-autobus',
		destination: '/es-MX/companies/nighjet',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-hoosier-stateela-bus-tickets',
		destination: '/en/companies/amtrak-hoosier-stateela',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-hoosier-stateela-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-hoosier-stateela',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-cascades-bus-tickets',
		destination: '/en/companies/amtrak-cascades',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-cascades-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-cascades',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/pisamover-bus-tickets',
		destination: '/en/companies/pisamover',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/pisamover-boletos-de-autobus',
		destination: '/es-MX/companies/pisamover',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/flixtrain-bus-tickets',
		destination: '/en/companies/flixtrain',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/flixtrain-boletos-de-autobus',
		destination: '/es-MX/companies/flixtrain',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-silver-service-bus-tickets',
		destination: '/en/companies/amtrak-silver-service',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-silver-service-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-silver-service',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/scotrail-bus-tickets',
		destination: '/en/companies/scotrail',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/scotrail-boletos-de-autobus',
		destination: '/es-MX/companies/scotrail',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-southwest-chief-bus-tickets',
		destination: '/en/companies/amtrak-southwest-chief',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-southwest-chief-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-southwest-chief',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/db-regio-bus-tickets',
		destination: '/en/companies/db-regio',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/db-regio-boletos-de-autobus',
		destination: '/es-MX/companies/db-regio',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/tgv-lyria-bus-tickets',
		destination: '/en/companies/tgv-lyria',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/tgv-lyria-boletos-de-autobus',
		destination: '/es-MX/companies/tgv-lyria',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/transpennine-express-bus-tickets',
		destination: '/en/companies/transpennine-express',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/transpennine-express-boletos-de-autobus',
		destination: '/es-MX/companies/transpennine-express',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-acela-bus-tickets',
		destination: '/en/companies/amtrak-acela',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-acela-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-acela',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-coast-starlight-bus-tickets',
		destination: '/en/companies/amtrak-coast-starlight',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-coast-starlight-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-coast-starlight',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/stansted-express-bus-tickets',
		destination: '/en/companies/stansted-express',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/stansted-express-boletos-de-autobus',
		destination: '/es-MX/companies/stansted-express',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/danish-rails-bus-tickets',
		destination: '/en/companies/danish-rails',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/danish-rails-boletos-de-autobus',
		destination: '/es-MX/companies/danish-rails',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/tgv-inoui-bus-tickets',
		destination: '/en/companies/tgv-inoui',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/tgv-inoui-boletos-de-autobus',
		destination: '/es-MX/companies/tgv-inoui',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/sncf-intercites-de-nuit-bus-tickets',
		destination: '/en/companies/sncf-intercites-de-nuit',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/sncf-intercites-de-nuit-boletos-de-autobus',
		destination: '/es-MX/companies/sncf-intercites-de-nuit',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-adirondack-bus-tickets',
		destination: '/en/companies/amtrak-adirondack',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-adirondack-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-adirondack',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/comboios-de-portugal-bus-tickets',
		destination: '/en/companies/comboios-de-portugal',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/comboios-de-portugal-boletos-de-autobus',
		destination: '/es-MX/companies/comboios-de-portugal',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/thames-link-bus-tickets',
		destination: '/en/companies/thames-link',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/thames-link-boletos-de-autobus',
		destination: '/es-MX/companies/thames-link',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-empire-builder-bus-tickets',
		destination: '/en/companies/amtrak-empire-builder',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-empire-builder-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-empire-builder',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/airtrain-brisbane-bus-tickets',
		destination: '/en/companies/airtrain-brisbane',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/airtrain-brisbane-boletos-de-autobus',
		destination: '/es-MX/companies/airtrain-brisbane',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/viarail-bus-tickets',
		destination: '/en/companies/viarail',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/viarail-boletos-de-autobus',
		destination: '/es-MX/companies/viarail',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/transport-for-wales-bus-tickets',
		destination: '/en/companies/transport-for-wales',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/transport-for-wales-boletos-de-autobus',
		destination: '/es-MX/companies/transport-for-wales',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/lner-bus-tickets',
		destination: '/en/companies/lner',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/lner-boletos-de-autobus',
		destination: '/es-MX/companies/lner',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/czech-railways-bus-tickets',
		destination: '/en/companies/czech-railways',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/czech-railways-boletos-de-autobus',
		destination: '/es-MX/companies/czech-railways',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-wolverine-bus-tickets',
		destination: '/en/companies/amtrak-wolverine',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-wolverine-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-wolverine',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/european-sleeper-bus-tickets',
		destination: '/en/companies/european-sleeper',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/european-sleeper-boletos-de-autobus',
		destination: '/es-MX/companies/european-sleeper',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/bienvenue-bus-tickets',
		destination: '/en/companies/bienvenue',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/bienvenue-boletos-de-autobus',
		destination: '/es-MX/companies/bienvenue',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/c2c-bus-tickets',
		destination: '/en/companies/c2c',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/c2c-boletos-de-autobus',
		destination: '/es-MX/companies/c2c',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/sncf-bus-tickets',
		destination: '/en/companies/sncf',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/sncf-boletos-de-autobus',
		destination: '/es-MX/companies/sncf',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-carl-sandburg-bus-tickets',
		destination: '/en/companies/amtrak-carl-sandburg',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-carl-sandburg-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-carl-sandburg',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/amtrak-vermonter-bus-tickets',
		destination: '/en/companies/amtrak-vermonter',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/amtrak-vermonter-boletos-de-autobus',
		destination: '/es-MX/companies/amtrak-vermonter',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/italo-bus-tickets',
		destination: '/en/companies/italo',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/italo-boletos-de-autobus',
		destination: '/es-MX/companies/italo',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/dutch-railways-bus-tickets',
		destination: '/en/companies/dutch-railways',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/dutch-railways-boletos-de-autobus',
		destination: '/es-MX/companies/dutch-railways',
		locale: false,
		permanent: true,
	},
	{
		source: '/en/ni-railways-bus-tickets',
		destination: '/en/companies/ni-railways',
		locale: false,
		permanent: true,
	},
	{
		source: '/es-MX/ni-railways-boletos-de-autobus',
		destination: '/es-MX/companies/ni-railways',
		locale: false,
		permanent: true,
	},
];

export function event(
	action,
	{ category, label, value, nonInteraction, userId, ...otherOptions }
) {
	if (!window.gtag) {
		return;
	}

	const eventOptions = { ...otherOptions };

	if (category !== undefined) {
		eventOptions.event_category = category;
	}

	if (label !== undefined) {
		eventOptions.event_label = label;
	}

	if (value !== undefined) {
		eventOptions.value = value;
	}

	if (nonInteraction !== undefined) {
		eventOptions.non_interaction = nonInteraction;
	}

	if (userId !== undefined) {
		eventOptions.user_id = userId;
	}

	window.gtag('event', action, eventOptions);
}

export function pageView(
	{ title, location, path, sendPageView, userId },
	measurementId
) {
	const gaMeasurementId =
		process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID ?? measurementId;

	if (!gaMeasurementId || !window.gtag) {
		return;
	}

	const pageViewOptions = {};

	if (title !== undefined) {
		pageViewOptions.page_title = title;
	}

	if (location !== undefined) {
		pageViewOptions.page_location = location;
	}

	if (path !== undefined) {
		pageViewOptions.page_path = path;
	}

	if (sendPageView !== undefined) {
		pageViewOptions.send_page_view = sendPageView;
	}

	if (userId !== undefined) {
		pageViewOptions.user_id = userId;
	}

	window.gtag('config', gaMeasurementId, pageViewOptions);
}

export function viewItemList({ routeId, routeName, schedules }) {
	try {
		console.log('viewItemList', routeId, routeName);
		if (!window.gtag) {
			return;
		}

		const items = schedules
			.map((schedule, index) => {
				return {
					item_id: schedule.externalId,
					item_name: routeName,
					item_brand: schedule.company.name,
					item_category: schedule.transportType,
					item_list_id: routeId,
					item_list_name: routeName,
					price: schedule.price.value,
					quantity: 1,
					index: index,
				};
			})
			.slice(0, 50);

		const data = {
			items: items,
		};

		console.log('viewItemList', data);
		window.gtag('event', 'view_item_list', data);
	} catch (error) {
		console.error(error);
	}
}

export function viewItem({ routeId, routeName, schedule }) {
	try {
		console.log('viewItem', routeId, routeName);
		if (!window.gtag) {
			return;
		}

		const item = {
			item_id: schedule.id,
			item_name: routeName,
			item_brand: schedule.company.name,
			item_category: schedule.transportType,
			item_list_id: routeId,
			item_list_name: routeName,
			price: schedule.price.value,
			quantity: 1,
			index: index,
		};

		const data = {
			currency: schedule.price.currency_code,
			value: schedule.price.value,
			item: item,
		};

		window.gtag('event', 'view_item', data);
	} catch (error) {
		console.error(error);
	}
}

import { project } from '@/lib/project';
import {
	SignedIn,
	SignedOut,
	SignInButton,
	UserButton,
	useUser,
} from '@clerk/nextjs';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import { useRouter } from 'next/router';
import Script from 'next/script';

export function AuthSignInSignOut() {
	const { user } = useUser();
	const router = useRouter();
	const redirectUrl = `https://${project.domain}/${router.locale}${router.asPath}`;

	return (
		<>
			{user && (
				<Script id="bing-enhanced-conversion">
					{`window.uetq=window.uetq||[];
						window.uetq.push('set', { 'pid': {
							'em': "${user.primaryEmailAddress.emailAddress}",
							'ph': "",
						}});
					`}
					;
				</Script>
			)}
			<SignedOut>
				<SignInButton
					mode="modal"
					forceRedirectUrl={redirectUrl}
					signUpForceRedirectUrl={redirectUrl}
				>
					<UserCircleIcon className="h-9 w-9 cursor-pointer text-gray-500 hover:text-gray-700 lg:h-8 lg:w-8" />
				</SignInButton>
			</SignedOut>
			<SignedIn>
				<UserButton />
			</SignedIn>
		</>
	);
}

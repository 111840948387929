const { project } = require('./src/lib/project');
const iconPath = `/icons/${project.id}`;

export default {
	titleTemplate: `%s | ${project.name}`,
	openGraph: {
		type: 'website',
		images: [
			{
				url: `https://${project.domain}${iconPath}/share.png`,
				width: 1200,
				height: 630,
				alt: project.name,
			},
		],
		url: `https://${project.domain}`,
		siteName: project.name,
	},
	addionalMetaTags: [
		{ name: 'viewport', content: 'initial-scale=1, width=device-width' },
		{ httpEquiv: 'X-UA-Compatible', content: 'IE=edge' },

		/** Apple Mobile */
		{ name: 'apple-mobile-web-app-title', content: project.name },
		{ name: 'apple-mobile-web-app-capable', content: 'yes' },
		{ name: 'apple-mobile-web-app-status-bar-style', content: 'black' },

		/** Microsoft */
		{ name: 'msapplication-TileColor', content: '#ffffff' },
		{
			name: 'msapplication-TileImage',
			content: `${iconPath}/ms-icon-144x144.png`,
		},
		{ name: 'msapplication-config', content: `${iconPath}/browserconfig.xml` },
		{
			name: 'theme-color',
			content: '#ffffff',
			media: '(prefers-color-scheme: light)',
		},
		{
			name: 'theme-color',
			content: '#ffffff',
			media: '(prefers-color-scheme: dark)',
		},
	],
	additionalLinkTags: [
		{
			rel: 'search',
			type: 'application/opensearchdescription+xml',
			title: 'Search your tickets here!',
			href: '/opensearch.xml',
		},
		/** Favicon */
		{
			rel: 'shortcut icon',
			href: `${iconPath}/favicon.ico`,
		},
		{
			rel: 'icon',
			href: `${iconPath}/favicon-16x16.png`,
			sizes: '16x16',
		},
		{
			rel: 'icon',
			href: `${iconPath}/favicon-32x32.png`,
			sizes: '32x32',
		},
		/** Apple Icons */
		// {
		// 	rel: 'apple-touch-startup-image',
		// 	href: '/icons/launch.png',
		// },
		{
			rel: 'apple-touch-icon',
			href: `${iconPath}/apple-touch-icon.png`,
		},
		{
			rel: 'apple-touch-icon',
			href: `${iconPath}/apple-touch-icon.png`,
			sizes: '152x152',
		},
		{
			rel: 'apple-touch-icon',
			href: `${iconPath}/apple-touch-icon.png`,
			sizes: '180x180',
		},
	],
};

const { project } = require('@/lib/project');
const { trainCompaniesRedirects } = require('@/train-companies-redirects.js');

const i18nRoute = (pathname, locale, params) => {
	const routePathname = project.routes[pathname];
	if (!routePathname) {
		return pathname;
	}
	let translatedPath = routePathname[locale];
	if (params && translatedPath) {
		Object.keys(params).map((key) => {
			if (Array.isArray(params[key])) {
				params[key].map((value) => {
					translatedPath = translatedPath.replace(/\:\w+/, value);
				});
			} else {
				translatedPath = translatedPath.replace(`:${key}`, params[key]);
			}
		});
	}

	const redirect = trainCompaniesRedirects.find(
		(redirect) => redirect.source === translatedPath
	);
	return redirect ? redirect.destination : translatedPath || '';
};

const getCanonicalUrl = (path, locale, params) => {
	const translatedPath = i18nRoute(path, locale, params);
	const url = `https://${project.domain}${translatedPath}`;
	return url.replace(/\/$/gm, '');
};

module.exports = {
	i18nRoute,
	getCanonicalUrl,
};

import logo1001 from '@/images/companies/1001.png';
import logoAdo from '@/images/companies/ado.png';
import logoAguiaBranca from '@/images/companies/aguia-branca.png';
import logoAguiaFlex from '@/images/companies/aguia-flex.svg';
import logoAlsa from '@/images/companies/alsa.png';
import logoAmtrakAcela from '@/images/companies/amtrak-acela.png';
import bgAmtrak from '@/images/companies/amtrak-bg.jpeg';
import logoAmtrak from '@/images/companies/amtrak.png';
import logoAPM from '@/images/companies/apm.png';
import logoBaronsBus from '@/images/companies/barons-bus.png';
import logoBestBus from '@/images/companies/best-bus.png';
import logoBigSkyExpress from '@/images/companies/big-sky-express.svg';
import logoBigTree from '@/images/companies/big-tree.png';
import logoBlablabus from '@/images/companies/blablacar-bus.png';
import logoBoltBus from '@/images/companies/bolt-bus.png';
import logoBrightline from '@/images/companies/brightline.png';
import logoBurlingtonTrailways from '@/images/companies/burlington-trailways.png';
import logoBuser from '@/images/companies/buser.png';
import logoCatarinense from '@/images/companies/catarinense.png';
import logoCitilinier from '@/images/companies/citilinier.png';
import logoCitytoCity from '@/images/companies/city-to-city.png';
import logoCitylink from '@/images/companies/citylink.png';
import logoCoachRun from '@/images/companies/coach-run.png';
import logoComboiosDePortugal from '@/images/companies/comboios-de-portugal.png';
import logoCometa from '@/images/companies/cometa.png';
import logoCrossCountry from '@/images/companies/cross-country.png';
import logoDeltaCoaches from '@/images/companies/delta-coaches.png';
import bgDeutscheBahn from '@/images/companies/deutsche-bahn-bg.jpeg';
import logoDeutscheBahn from '@/images/companies/deutsche-bahn.png';
import logoEagleLiner from '@/images/companies/eagle-liner.png';
import logoEldoCoaches from '@/images/companies/eldo-coaches.png';
import logoEmpireCoachLine from '@/images/companies/empire-coach-line.png';
import logoEucatur from '@/images/companies/eucatur.webp';
import logoEurolines from '@/images/companies/eurolines.png';
import logoEurostar from '@/images/companies/eurostar.png';
import logoExpressoAdamantina from '@/images/companies/expresso-adamantina.png';
import logoExpressoSul from '@/images/companies/expresso-do-sul.png';
import logoExpressoGuanabara from '@/images/companies/expresso-guanabara.png';
import bgFlixbus from '@/images/companies/flixbus-bg.png';
import logoFlixbus from '@/images/companies/flixbus.png';
import logoFlixtrain from '@/images/companies/flixtrain.png';
import logoGarcia from '@/images/companies/garcia.png';
import logoGardenia from '@/images/companies/gardenia.png';
import logoGipsyy from '@/images/companies/gipsyy.png';
import logoGoBuses from '@/images/companies/go-buses.png';
import logoGontijo from '@/images/companies/gontijo.png';
import logoGreyhoundAustralia from '@/images/companies/greyhound-australia.png';
import bgGreyhound from '@/images/companies/greyhound-bg.png';
import logoGreyhoundAfrica from '@/images/companies/greyhound-south-africa.png';
import logoGreyhound from '@/images/companies/greyhound.png';
import logoIC from '@/images/companies/ic.webp';
import logoICE from '@/images/companies/ice.png';
import logoIntercape from '@/images/companies/intercape.svg';
import logoIntercityXpress from '@/images/companies/intercity-xpress.png';
import logoItalo from '@/images/companies/italo.png';
import logoItapemirim from '@/images/companies/itapemirim.webp';
import logoJeffersonLines from '@/images/companies/jefferson-lines.png';
import logoJetSet from '@/images/companies/jet-set.png';
import logoLasVegasShuttles from '@/images/companies/las-vegas-shuttles.png';
import logoLner from '@/images/companies/lner.png';
import logoLosLimousines from '@/images/companies/los-limousines.png';
import logoMarino from '@/images/companies/marino.svg';
import bgMegabus from '@/images/companies/megabus-bg.jpeg';
import logoMegabus from '@/images/companies/megabus.png';
import bgNationalExpress from '@/images/companies/national-express-bg.jpeg';
import logoNationalExpress from '@/images/companies/national-express.png';
import logoNorthernRailway from '@/images/companies/northern-railway.png';
import logoOBB from '@/images/companies/oebb.png';
import logoOurBus from '@/images/companies/ourbus.svg';
import logoPenha from '@/images/companies/penha.png';
import peterPanBusBg from '@/images/companies/peter-pan-bus-bg.jpeg';
import logoPeterPan from '@/images/companies/peter-pan.png';
import logoRedCoach from '@/images/companies/red-coach.png';
import logoRedeExpressos from '@/images/companies/rede-expressos.png';
import logoRegioJet from '@/images/companies/regiojet.png';
import logoRenfe from '@/images/companies/renfe.png';
import logoReunidas from '@/images/companies/reunidas.svg';
import logoRioDoce from '@/images/companies/riodoce.png';
import logoRockledgeBus from '@/images/companies/rockledge-bus.png';
import logoSBB from '@/images/companies/sbb.png';
import logoSindbad from '@/images/companies/sindbad.png';
import logoSNCF from '@/images/companies/sncf.png';
import logoStagecoach from '@/images/companies/stagecoach.png';
import logoStudentAgency from '@/images/companies/student-agency.png';
import logoTranslux from '@/images/companies/translux.png';
import logoTranspenineExpress from '@/images/companies/transpennine-express.png';
import logoTrenitalia from '@/images/companies/trenitalia.png';
import logoTripperBus from '@/images/companies/tripper-bus.png';
import logoUnicaFacil from '@/images/companies/unica-facil.png';
import logoUtil from '@/images/companies/util.svg';
import logoWamoose from '@/images/companies/vamoose.png';
import logoVamusAlgarve from '@/images/companies/vamus-algarve.png';
import logoViarail from '@/images/companies/viarail.png';
import logoWandaCoach from '@/images/companies/wanda-coach.png';
import logoWashingtonDeluxe from '@/images/companies/washington-deluxe.png';
import logoWeMobi from '@/images/companies/wemobi.png';
import logoWezweCoaches from '@/images/companies/wezwe-coaches.png';

// UI
import faviconBusBuster from '@/images/icons/logo-busbuster-icon.png';
import logoBusBuster from '@/images/projects/busbuster.svg';

const Logos = {
	//UI
	busbuster: logoBusBuster,

	// Favicon
	'icon-busbuster': faviconBusBuster,

	citiliner: logoCitilinier,
	'eagle-liner': logoEagleLiner,
	intercape: logoIntercape,
	translux: logoTranslux,
	'greyhound-south-africa': logoGreyhoundAfrica,
	'eldo-coaches': logoEldoCoaches,
	'city-to-city': logoCitytoCity,
	'intercity-xpress': logoIntercityXpress,
	'big-sky-express': logoBigSkyExpress,
	'delta-coaches': logoDeltaCoaches,
	'wezwe-coaches': logoWezweCoaches,
	'big-tree': logoBigTree,
	greyhound: logoGreyhound,
	'peter-pan-bus': logoPeterPan,
	'red-coach': logoRedCoach,
	'bolt-bus': logoBoltBus,
	'wanda-travel': logoWandaCoach,
	'coach-run': logoCoachRun,
	'jefferson-lines-trailways': logoJeffersonLines,
	'go-buses': logoGoBuses,
	'jet-set': logoJetSet,
	ourbus: logoOurBus,
	amtrak: logoAmtrak,
	viarail: logoViarail,
	'rockledge-bus': logoRockledgeBus,
	ado: logoAdo,
	'burlington-trailways': logoBurlingtonTrailways,
	'greyhound-australia': logoGreyhoundAustralia,
	'deutsche-bahn': logoDeutscheBahn,
	eurostar: logoEurostar,
	renfe: logoRenfe,
	sncf: logoSNCF,
	trenitalia: logoTrenitalia,
	alsa: logoAlsa,
	eurolines: logoEurolines,
	megabus: logoMegabus,
	flixbus: logoFlixbus,
	'national-express': logoNationalExpress,
	blablacarbus: logoBlablabus,
	stagecoach: logoStagecoach,
	oebb: logoOBB,
	sbb: logoSBB,
	'deutsche-bahn-intercity-express-ice': logoICE,
	'deutsche-bahn-intercity': logoIC,
	italo: logoItalo,
	marino: logoMarino,
	'expresso-guanabara': logoExpressoGuanabara,
	'expresso-do-sul': logoExpressoSul,
	util: logoUtil,
	itapemirim: logoItapemirim,
	cometa: logoCometa,
	reunidas: logoReunidas,
	1001: logo1001,
	eucatur: logoEucatur,
	gontijo: logoGontijo,
	garcia: logoGarcia,
	'aguia-branca': logoAguiaBranca,
	catarinense: logoCatarinense,
	gardenia: logoGardenia,
	buser: logoBuser,
	wemobi: logoWeMobi,
	penha: logoPenha,
	riodoce: logoRioDoce,
	'expresso-adamantina': logoExpressoAdamantina,
	'aguia-flex': logoAguiaFlex,
	'comboios-de-portugal': logoComboiosDePortugal,
	gipsyy: logoGipsyy,
	'rede-expressos': logoRedeExpressos,
	'vamus-algarve': logoVamusAlgarve,
	brightline: logoBrightline,
	'amtrak-acela': logoAmtrakAcela,
	'unica-facil': logoUnicaFacil,
	'best-bus': logoBestBus,
	'washington-deluxe': logoWashingtonDeluxe,
	vamoose: logoWamoose,
	'tripper-bus': logoTripperBus,
	'barons-bus': logoBaronsBus,
	regiojet: logoRegioJet,
	'student-agency': logoStudentAgency,
	sindbad: logoSindbad,
	citylink: logoCitylink,
	'cross-country': logoCrossCountry,
	lner: logoLner,
	'transpennine-express': logoTranspenineExpress,
	'northern-railway': logoNorthernRailway,
	'las-vegas-shuttles': logoLasVegasShuttles,
	'los-limousines': logoLosLimousines,
	apm: logoAPM,
	flixtrain: logoFlixtrain,
	'empire-coach-line': logoEmpireCoachLine,
};

const Backgrounds = {
	amtrak: bgAmtrak,
	greyhound: bgGreyhound,
	flixbus: bgFlixbus,
	megabus: bgMegabus,
	'deutsche-bahn': bgDeutscheBahn,
	'national-express': bgNationalExpress,
	'peter-pan-bus': peterPanBusBg,
};

export function CompanyBackground({ id }) {
	if (id && id.startsWith('amtrak')) {
		return Backgrounds['amtrak'];
	}
	return Backgrounds[id];
}

export function StaticLogo({ id }) {
	if (id && id.startsWith('amtrak')) {
		return Logos['amtrak'];
	}
	return Logos[id];
}
